<template>
	<div id="sales_dep">
		<div class="main-body-filter">
			<div class="main-body-filter-left" style="display: flex;">
				<el-select v-model="filter.bigarea.value" :loading="filter.bigarea.is_load" class="filter-select"
					size="large" clearable filterable placeholder="全部区域" @change="selectFilter">
					<el-option v-for="item in filter.bigarea.options" :key="item.bg_id" :label="item.bg_areaname"
						:value="item.bg_id">
					</el-option>
				</el-select>
				<el-select v-if="filter.salesDep.options.length != 0" v-model="filter.salesDep.value" :loading="filter.salesDep.is_load" class="filter-select"
					size="large" clearable filterable placeholder="全部售楼部" @change="selectFilter">
					<el-option v-for="item in filter.salesDep.options" :key="item.sd_id" :label="item.sd_name"
						:value="item.sd_id">
					</el-option>
				</el-select>
			  <input type="file" ref="files" style="display: none;" @change="changeFile"/>
				<el-button v-if="filter.salesDep.value" :icon="Picture" type="primary" size="large" @click="searchPic">人脸照片</el-button>
			</div>
		</div>
		<div class="main-body-table" v-loading="table.conf.loadStatus">
			<SelectTable v-if="table.list.length != 0" ref="table_ref" :tables="table.list" :confs="table.conf" :filters="table.columns" :icons="['RefreshRight']" :operate="true" @sizeChange="tablePageSize" @currentChange="tableChosePage" @iconClick="refreshFace"></SelectTable>
		</div>
	</div>
</template>

<script setup>
	import tableColumns from '@/assets/json/face/table/face-search.json'
	import SelectTable from '@/components/common/SelectTable.vue'
	import axios from 'axios'
	import {
		Picture
	} from '@element-plus/icons-vue'
	import {
		getCurrentInstance,
		ref,
		onMounted,
		reactive
	} from 'vue'
	import {
		ElMessage,
		ElMessageBox
	} from 'element-plus'

	// 访问 globalProperties
	const ctx = getCurrentInstance().appContext.config.globalProperties

	// 顶部筛选
	const filter = reactive({
		keywords: '',
		bigarea: {
			options: [],
			value: '',
			is_load: false
		},
		salesDep: {
			options: [],
			value: '',
			is_load: false
		}
	});
	// 文件转base64
	const getPictureBase64 = (file) => {
		return new Promise((resolve, reject) => {
			const reader = new FileReader();
			reader.readAsDataURL(file);
			reader.onload = () => resolve(reader.result);
			reader.onerror = (error) => reject(error);
		});
	};

	const files = ref(null)

	const searchPic = () =>{
		files.value.click()
	}

	const changeFile = async (event) =>{
		let fileBase64 =  await getPictureBase64(files.value.files[0]);
		table.conf.img_info = fileBase64;
		loadList(1)
		event.target.value = '';
	}

	// 列表信息
	const table = reactive({
		list: [],
		columns: tableColumns.list,
		conf: {
			loadStatus: false,
			curPage: 1,
			pageLimit: 10,
			dataTotal: 0,
			emptyText: '',
			img_info:'',
			type: 1,
			faceTokens:''
		}
	})

	// 获取table列表
	const loadList = (num = 1) => {
		// 清除现有table数据
		table.list = []
		table.conf.loadStatus = true
		table.conf.emptyText = '数据查询中...';
		let data = {}
		if(num == 1) {
			data = {
				bg_id: filter.bigarea.value,
				sd_id: filter.salesDep.value,
				img_info: table.conf.img_info,
				type: table.conf.type,
			}
		} else if(num == 2) {
			data = {
				bg_id: filter.bigarea.value,
				sd_id: filter.salesDep.value,
				img_info: table.conf.img_info,
				type: 2,
				faceTokens: table.conf.faceTokens,
				page: table.conf.curPage,
				limit: table.conf.pageLimit,
			}
		}
		ctx.$request_nl_.post(ctx.$api_.state.Face.face_set.search.url, data)
			.then((respon) => {
				if (respon.Code === ctx.$code_.state.success) {
					// 第一次请求
					if(num == 1) {
						if(respon.Data) {
							table.conf.faceTokens = respon.Data
							loadList(2)
						} else {
							table.conf.loadStatus = false
							ctx.$message.error({
								message: respon.Message
							});
						}
					} else if(num == 2) {
						table.conf.dataTotal = respon.Data.total
						table.list = respon.Data.list
						for (let index = 0; index < respon.Data.list.length; index++) {
							table.list[index].img_arr = [respon.Data.list[index].fb_face_key]
						}
						table.conf.loadStatus = false
					}
				} else {
					table.conf.loadStatus = false
					ctx.$message.error({
						message: respon.Message
					});
				}
				table.conf.emptyText = respon.Data.list.length > 0 ? respon.Message : "暂无更多数据"
			}).catch(error => {
				table.conf.loadStatus = false
				table.conf.emptyText = "服务器连接失败，请稍后重试"
			})
	}

	// 重新加载人脸
	const refreshFace = (value, item) => {
		// 重新加载人脸合集
		ElMessageBox.confirm(
			'您确定要重新加载此数据?',
			'温馨提示', {
				confirmButtonText: 'OK',
				cancelButtonText: '取消',
				type: 'warning',
		}).then(() => {
			reloadFace(item.img_arr)
		})
	}

	// 重新加载人脸信息
	const reloadFace = (arr) => {
		let sendAry = [
			axios.get(arr[0]+ '?restore')
		];
		axios.all(sendAry).then(result => {
			ctx.$message.success({
				message: '资源已重新刷新'
			});
		}).catch(function (error) {
			ctx.$message.success({
				message: '资源已重新刷新'
			});
		});
	}

	// 筛选框调用事件
	const selectFilter = () => {
		filter.keywords = ''
		table.conf.curPage = 1
		// 重新加载table数据
		getSalesDepList()
	}

	// 输入框调用事件
	const inputFilter = () => {
		// 清空select选项框
		filter.bigarea.value = ''
		table.conf.curPage = 1
		// 重新加载table数据
	}

	// 设置table当前页码变化
	const tableChosePage = (curPage) => {
		// 存放当前页面
		table.conf.curPage = curPage
		// 重新加载table数据
		loadList(2)
	}

	// 设置table每页页码数
	const tablePageSize = (limit, page) => {
		// 存放页码数
		table.conf.pageLimit = limit
		// 判断查询当前页  *  条数 > 总条数不进行查询
		if (page === 1 || limit * page <= table.conf.dataTotal) {
			// 重新加载table数据
			loadList(2)
		}
	}

	// 挂载
	const table_ref = ref(null)
	const add_sales_dep_ref = ref(null)

	//获取售楼部
	const getSalesDepList = () => {
		ctx.$request_nl_.post(ctx.$api_.state.Face.sales_dep.list.url, {
				bg_id: filter.bigarea.value,
				page: 1,
				limit: 1000
			})
			.then((respon) => {
				if (respon.Code === ctx.$code_.state.success) {
					filter.salesDep.options = respon.Data.list
				} else {
					ctx.$message.error({
						message: respon.Message
					});
				}
			})
	}

	onMounted(() => {
		// 初始化加载区域列表
		ctx.$pubFun_.getSelectCommonBigarea(filter.bigarea)

		// 初始化加载表格列表
		// loadList()
	})
</script>